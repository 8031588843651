import { Component, Input } from '@angular/core';
import {
  SOCIAL_NETWORK_ICONS_MAP,
  SOCIAL_NETWORK_NAMES_MAP,
} from './social-network-icon.constants';
import { MppSocialNetwork } from '@arturin/core';

@Component({
  selector: 'cf-social-network-icon',
  templateUrl: './social-network-icon.component.html',
  styleUrls: ['./social-network-icon.component.scss'],
})
export class CfSocialNetworkIconComponent {
  @Input() public type: MppSocialNetwork;

  @Input() public displayText = true;

  public get labelText(): string {
    return SOCIAL_NETWORK_NAMES_MAP[this.type]!;
  }

  public get icon(): string {
    return SOCIAL_NETWORK_ICONS_MAP[this.type]!;
  }
}
