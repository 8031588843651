import { MppSocialNetwork } from '@arturin/core';

export const SOCIAL_NETWORK_NAMES_MAP: Partial<Record<MppSocialNetwork, string>> = {
  [MppSocialNetwork.FACEBOOK]: 'Facebook',
  [MppSocialNetwork.GOOGLE]: 'Google',
};
export const SOCIAL_NETWORK_ICONS_MAP: Partial<Record<MppSocialNetwork, string>> = {
  [MppSocialNetwork.FACEBOOK]: 'facebook-filled',
  [MppSocialNetwork.GOOGLE]: 'google-filled',
};
